<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__left">
        <div @click="handleCurrentPage('contact')" class="footer__touch biggest-cursor-hover">
          <span class="underline"></span>
          <nobr>{{ $t('footer.touch') }}</nobr>
        </div>
        <div class="footer__additional">
          <p class="footer__text">INCHKYIV © 2022</p>

          <div class="footer__link">
            <p>
              <router-link to="/privacy-policy" class="footer__text link cursor-hover">{{ $t('footer.privacyPolicy') }}</router-link>
            </p>
            <p>
              <router-link to="/data-processing-agreement" class="footer__text link cursor-hover">{{ $t('footer.data-processing-agreement') }}</router-link>
            </p>
            <p>
              <router-link to="/code-of-conduct" class="footer__text link cursor-hover">{{ $t('footer.code-of-conduct') }}</router-link>
            </p>
            <p>
              <router-link to="/term-of-service" class="footer__text link cursor-hover">{{ $t('footer.term-of-service') }}</router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="footer__right">
        <ul class="footer__sublist left">
          <h5 v-if="getCurrentLangs.lang === 'en'" class="footer__subtitle">{{ $t('footer.right') }}</h5>
          <div class="footer__sublist--wrapper">
            <li class="footer__sublist--item cursor-hover">
              <a @click="handleToHome">
                {{ $t('footer.agency') }}
              </a>
            </li>
            <li class="footer__sublist--item cursor-hover">
              <router-link to="/cases">
                {{ $t('footer.cases') }}
              </router-link>
            </li>
            <li class="footer__sublist--item cursor-hover">
              <router-link to="/services">
                {{ $t('footer.services') }}
              </router-link>
            </li>
            <li class="footer__sublist--item cursor-hover">
              <a
                href="/INCH360_VR_platform_presentation_EN.pdf"
                target="_blank"
              >
                {{ $t('footer.inch360') }}
                <span class="grey" v-html="$t('footer.vrPlatform')"></span>
              </a>
            </li>
          </div>
        </ul>
        <div class="footer__wrapper">
          <ul class="footer__sublist">
            <h5 class="footer__subtitle">{{ $t('footer.sayHi') }}</h5>
            <div class="footer__sublist--wrapper">
              <p class="email-link">
                <a class="footer__link cursor-hover" href="mailto:agency@inchkiev.ua">agency@inchkiev.ua</a>
              </p>
            </div>
          </ul>
          <ul class="footer__sublist">
            <h5 class="footer__subtitle">{{ $t('footer.address') }}</h5>
            <div class="footer__sublist--wrapper">

              <li class="footer__sublist--item cursor-hover">
                <a
                  href="https://goo.gl/maps/r56CmgBvRbF45VwG8"
                  target="_blank"
                >
                  {{ $t('footer.street[0]') }}
                  <br>
                  <span v-html="$t('footer.street[1]')"> </span>
                </a>
              </li>


            </div>
          </ul>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="footer__additional">
          <p class="footer__text">INCHKYIV © 2022</p>

          <div class="footer__link">
            <p>
              <router-link to="/privacy-policy" class="footer__text link cursor-hover">{{ $t('footer.privacyPolicy') }}</router-link>
            </p>
            <p>
              <router-link to="/data-processing-agreement" class="footer__text link cursor-hover">{{ $t('footer.data-processing-agreement') }}</router-link>
            </p>
            <p>
              <router-link to="/code-of-conduct" class="footer__text link cursor-hover">{{ $t('footer.code-of-conduct') }}</router-link>
            </p>
            <p>
              <router-link to="/term-of-service" class="footer__text link cursor-hover">{{ $t('footer.term-of-service') }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'NewFooter',
  computed: {
    ...mapGetters([
      'getCurrentPage',
      'getCurrentLangs'
    ]),
  },
  methods: {
    ...mapActions([
      'setCurrentPage',
    ]),

    handleCurrentPage(pageName) {
      this.setCurrentPage(pageName);
      this.$router.push('/' + pageName)
    },

    handleToHome() {
      if (this.getCurrentPage === 'home') {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      } else {
        window.location = '/'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: var(--background-footer-color);
  padding: 100px 45px 88px 45px;
  text-align: left;
  position: relative;
  z-index: 2;
  display: flex;
  // justify-content: space-between;
  min-height: 386px;
  border-top: 1px solid var(--footer-divider-color);
  margin: auto 0 0 0;

  .email-link {
    display: inline-block;

    a {
      text-decoration: none;
      background: linear-gradient(to right, rgba(100, 200, 200, 0), rgba(100, 200, 200, 0)),
      linear-gradient(to right, rgba(146, 161, 174, 1), rgba(146, 161, 174, 1));
      background-size: 100% 0.1em, 0 0.1em;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 400ms;

      @media screen and (min-width: 1023px) {
        &:hover {
          background-size: 0 1px, 100% 1px;
        }
      }

      &:active {
        background-size: 0 1px, 100% 1px;
      }
    }
  }

  &__container {
    display: flex;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
  }

  &__touch {
    font-family: "TimesNewRoman-I";
    font-style: italic;
    font-weight: 400;
    font-size: 80px;
    line-height: 80px;
    color: var(--footer-text-color);
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: var(--menu-icon-color);
      position: absolute;
      bottom: -4px;
      left: 0;
      transition-duration: 0.5s;
    }

    &:hover {
      &::after {
        width: 0%;
      }
    }
  }

  &__additional {
    display: flex;
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 154px;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 540px;

    .footer__sublist {
      &.left {
        min-width: 178px;
        max-width: 178px;
      }
    }

  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    color: #92A1AE;
    font-weight: 500;
    margin-bottom: 12px;
    margin-top: 0;
    letter-spacing: 0.08em;
  }

  &__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;


  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(146, 161, 174, 1);
    letter-spacing: 0.04em;
    margin-right: 32px;
    margin-bottom: 20px;


  }

  &__sublist {
    &--item {
      margin-bottom: 22px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--footer-text-color);
      transform: translateX(-20px);
      padding-left: 20px;

      a {
        text-decoration: none;
        background: linear-gradient(to right, rgba(100, 200, 200, 0), rgba(100, 200, 200, 0)),
        linear-gradient(to right, rgba(146, 161, 174, 1), rgba(146, 161, 174, 1));
        background-size: 100% 0.1em, 0 0.1em;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;

        @media screen and (min-width: 1023px) {
          &:hover {
            background-size: 0 1px, 100% 1px;
          }
        }

        &:active {
          background-size: 0 1px, 100% 1px;
        }
      }

      &.street {
        margin-bottom: 0;
        line-height: 20px;

        span {
          color: var(--footer-text-color);
        }
      }

      .grey {
        color: #92A1AE;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .footer__sublist:last-of-type {
      margin-bottom: 28px;
    }
  }

  &__bottom {
    display: none;
  }

  &__link {
    display: flex;


  }
}

.footer__link p {
  display: inline-block;
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }

}

.footer__text.link {
  margin-right: 0px;

  padding: 4px 0;
  text-decoration: none;
  background: linear-gradient(to right, rgba(100, 200, 200, 0), rgba(100, 200, 200, 0)),
  linear-gradient(to right, rgba(146, 161, 174, 1), rgba(146, 161, 174, 1));
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

  @media screen and (min-width: 1023px) {
    &:hover {
      background-size: 0 1px, 100% 1px;
    }
  }

  &:active {
    background-size: 0 1px, 100% 1px;
  }
}

@media screen and (max-width: 1600px) {
  .footer {
    &__left {
      margin-right: 14%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .footer {

    &__left {
      margin-right: 12%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    &__right > .footer__sublist {
      margin-right: 32px;
    }

    &__touch {
      font-size: 64px;
      line-height: 64px;
      max-width: fit-content;
    }
  }
}

@media screen and (max-width: 1240px) {
  .footer {
    .footer__link p {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 960px) {
  .footer {
    padding: 54px 45px 36px 45px;


    &__sublist {
    }

    &__container {
      flex-direction: column;
    }

    &__bottom {
      display: flex;
      margin-top: 50px;
      width: 100%;

      .footer__additional {
        flex-direction: row;
        width: 100%;

        > .footer__text {
          min-width: 178px;
          margin-right: 20%;
        }
      }
    }

    &__text {
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &__left {
      .footer__additional {
        display: none;
      }
    }

    &__right {
      margin-top: 48px;
      justify-content: flex-start;
      max-width: 100%;
      width: 100%;

      & > .footer__sublist {
        margin-right: 20%;
      }
    }

    &__sublist {
      &--item {
        max-width: 56%;

        &:nth-of-type(4) {
          max-width: 100%;
        }

        &.street > br {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 48px;

    &__touch {
      font-size: 44px;
      line-height: 44px;
    }

    &__subtitle {
      margin-bottom: 14px;
    }

    &__bottom {


      .footer__additional {

        > .footer__text {
          margin-right: 10%;
        }
      }
    }

    &__text {
      margin-right: 10%;
    }

    &__right {
      & > .footer__sublist {
        margin-right: 10%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding-bottom: 32px;


    &__right {

      .footer__sublist {
        &.left {
          max-width: 100%;
          min-width: 1px;
        }
      }

    }

    &__right {
      flex-direction: column;

      & > .footer__sublist {
        margin-right: 0;
      }
    }

    &__left {
      margin-right: 26px;
    }

    &__text {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

    }

    &__link {
      line-height: unset;
    }

    &__additional {
      justify-content: space-between;
    }

    &__list {
      grid-template-columns: 1fr;

      &--left {
        .footer__sublist:first-of-type {
          margin-bottom: 22px;
        }
      }

      &--right {
        .footer__sublist:first-of-type {
          margin-bottom: 0px;
        }
      }
    }

    &__sublist {
      display: grid;
      grid-template-columns: 39% 1fr;
      grid-column-gap: 8%;
      margin-bottom: 16px;

      &--item {
        padding-left: 0;
        transform: translateX(0);
      }
    }


    &__bottom {


      .footer__additional {
        display: grid;
        grid-template-columns: 39% 1fr;
        grid-column-gap: 8%;

        > .footer__text {
        }
      }
    }

    &__bottom {
      justify-content: space-between;

      & > .footer__text:first-of-type {
        display: none;
      }

      & > .right {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;

        .footer__text {

          &.first {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;

    &__bottom {
      margin-top: 22px;
    }

    &__link {
      p {
        margin-bottom: 10px;
      }
    }

    &__bottom {
      .footer__link {
        flex-direction: column;
        width: 100%;

        a {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__sublist {
      &--item {
        max-width: unset;
      }
    }
  }
}
</style>
